<template>
    <div class="statePage">
      <template v-if="stateContent">
     <p class="stateTitle textCenter">{{stateContent.name}}</p>
    <div class="stateContent baseFour" v-html="stateContent.ext.disclaimers">
    
    </div>
  </template>
  <el-empty description="暂未设置免责声明" v-else></el-empty>
    </div>
</template>
<script>
  import api from '../../http/api'
  export default{
    data(){
      return {
        stateContent:'',//免责声明
      }
    },
   activated(){
      let that=this;
     api.getDispose({
      call_index:'Disclaimer'
     }).then(res=>{
        //console.log('免责声明',res)
        if(res&&res!=null){
          that.stateContent=res
        }
     })
    },
    methods:{

    }
  }
</script>
<style lang="less" scoped>
.statePage{
    border:1px solid #DCDCDC;
    height:100%;
    max-height:100%;
    overflow-y: auto;
  padding:18px 24px;
  overflow-x: hidden;
  .stateTitle{
    font-size:18px;
    font-weight:bold;
    margin-bottom:10px;
  
}
.stateContent{
  color:#333;
    line-height:30px;
}
}
</style>